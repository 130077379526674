import React from 'react';
import PropTypes from 'prop-types';

function TestimonialBlock({ testimonial }) {
  const { image, title, name, description } = testimonial;
  //   return (
  //     <section className="bg-white overflow-hidden text-gray-600 body-font">
  //       <div className="container px-5 py-12 md:py-20 lg:py-24 mx-auto">
  //         <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
  //           {/* <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             fill="currentColor"
  //             className="inline-block w-8 h-8 text-green-400 mb-8"
  //             viewBox="0 0 975.036 975.036"
  //           >
  //             <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z" />
  //           </svg> */}
  //           <div className="hidden lg:block lg:flex-shrink-0">
  //             <img
  //               className="object-contain mx-auto block h-64 w-64 rounded-full xl:h-80 xl:w-80"
  //               src={image}
  //               alt={title}
  //             />
  //           </div>
  //           <p className="leading-relaxed text-lg">&ldquo;{description}&rdquo;</p>
  //           {/* <div className="text-center text-2xl leading-9 font-medium text-gray-900">
  //             <p>&ldquo;{description}&rdquo;</p>
  //           </div> */}
  //           <span className="inline-block h-1 w-10 rounded bg-primaryColor mt-8 mb-6" />
  //           <h2 className="text-gray-900 text-base font-medium title-font tracking-wider text-sm uppercase mb-2">
  //             {name}
  //           </h2>
  //           <p className="text-gray-500 text-base font-medium">{title}</p>
  //         </div>
  //       </div>
  //     </section>
  //   );
  return (
    <section className="pt-6 bg-white overflow-hidden md:pt-12 lg:pt-24">
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="relative">
          <blockquote className="rounded-lg">
            {/* <img className="mx-auto h-8" src={image} alt={title} /> */}
            <div className="hidden lg:block lg:flex-shrink-0">
              <img
                className="object-contain mx-auto block h-64 w-64 rounded-full xl:h-80 xl:w-80"
                src={image}
                alt={title}
              />
            </div>
            <div className="max-w-3xl mx-auto text-center text-xl lg:text-2xl leading-9 font-medium text-gray-900">
              <p>&ldquo;{description}&rdquo;</p>
            </div>
            <footer className="my-8">
              <div className="md:flex md:items-center md:justify-center">
                {/* <div className="md:flex-shrink-0">
                  <img
                    className="mx-auto h-10 w-10 rounded-full"
                    src={image}
                    alt={title}
                  />
                </div> */}
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">
                    {name}
                  </div>

                  <svg
                    className="hidden md:block mx-1 h-5 w-5 text-primaryColor"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">
                    {title}
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  );
}

TestimonialBlock.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  testimonial: PropTypes.object,
};

export default TestimonialBlock;
