import PageLayout from '@global/page-layout';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import TestimonialBlock from '@src/containers/TestimonialBlock';
import CTA3 from '@containers/CTA3';
import { popup } from '@src/global/utils/typeformPopup';

const TestimonialsPage = () => {
  const data = useStaticQuery(graphql`
    query allTestimonialsSectionJson {
      testimonialsSectionJson {
        title
        subTitle
        highlightedTestimonials {
          link
          title
          image
          description
          name
          type
        }
      }
    }
  `);

  const {
    title,
    subTitle,
    highlightedTestimonials,
  } = data.testimonialsSectionJson;

  return (
    <PageLayout title="Testimonials">
      <div className="bg-gray-200 pt-6">
        <section className="text-gray-700 body-font">
          <div className="container px-5 py-24 mx-auto ">
            <div className="flex flex-wrap w-full mb-20">
              <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
                <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
                  {title}
                </h1>
                <div className="h-1 w-20 bg-green-500 rounded" />
              </div>
              <p className="lg:w-1/2 w-full leading-relaxed">{subTitle}</p>
            </div>
          </div>
        </section>
        <div>
          {highlightedTestimonials?.map((testimonial, index) => (
            <TestimonialBlock key={index} testimonial={testimonial} />
          ))}
        </div>
        <CTA3 gray onPress={() => popup()} />
      </div>
    </PageLayout>
  );
};

export default TestimonialsPage;
